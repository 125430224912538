<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h3 class="mb-3">
          Check your email inbox and phone sms<br>
          Click on the link in email message or enter your code from email and phone message.
        </h3>
        <b-card>
          <h2 class="mb-1">
            Enter your codes
          </h2>
          <b-alert
            class="mb-2 p-1"
            show
            variant="danger"
          >
            Why have the codes not arrived
            <b-button
              v-b-modal.modal-center
              class="more_information"
              variant="outline"
            >
              <b-icon
                icon="question-circle-fill"
              />
            </b-button>
            <b-modal
              id="modal-center"
              centered
              hide-footer
            >
              <p
                class="my-1"
              >
                1. You incorrectly entered your email or phone number during registration.<br>
              </p>
              <p
                class="my-1"
              >
                2. There are problems with your email account.<br>
              </p>
              <p
                class="my-1"
              >
                3. The verification email is in your spam folder<br>
              </p>
            </b-modal>
          </b-alert>
          <div class="page_codes">
            <div
              v-if="!userData.confirmed_email"
              class="page_codes_item"
              sm="6"
            >
              <b-form-group
                label="EMAIL code"
                label-for="input-lg"
                class="mb-0"
              >
                <b-form-input
                  id="input-lg"
                  v-model="verifyCodeEmail"
                  size="lg"
                />
              </b-form-group>
              <b-alert
                show
                variant="warning"
                class="mb-25 mt-1"
              >
                <div class="alert-body">
                  <b-link
                    class="alert-link"
                    @click="resend_verify_code_email"
                  >
                    Resend email confirmation
                  </b-link>
                </div>
              </b-alert>
            </div>
            <div
              v-if="!userData.confirmed_phone"
              class="page_codes_item"
              sm="6"
            >
              <b-form-group
                label="SMS code"
                label-for="input-lg"
                class="mb-0"
              >
                <b-form-input
                  id="input-lg"
                  v-model="verifyCodePhone"
                  size="lg"
                />
              </b-form-group>
              <b-alert
                show
                variant="warning"
                class="mb-25 mt-1"
              >
                <div class="alert-body">
                  <b-link
                    class="alert-link"
                    @click="resend_verify_code_phone"
                  >
                    Resend phone confirmation
                  </b-link>
                </div>
              </b-alert>
            </div>
          </div>
          <b-row>
            <b-col sm="12">
              <b-button
                variant="primary"
                class="mb-2 mt-2 m-1 btn-sm-block"
                @click="confirm"
              >
                Confirm
              </b-button>
              <b-button
                variant="secondary"
                class="mb-2 mt-2 m-1 btn-sm-block"
                @click="logout"
              >
                Log out
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BButton, BFormInput, BAlert, BCard, BCol, BRow, BFormGroup,
}
from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    BButton,
    BFormInput,
    BAlert,
    BCard,
    BCol,
    BRow,
    BFormGroup,
  },
  data() {
    return {
      verifyCodeEmail: '',
      verifyCodePhone: '',
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    resend_verify_code_email() {
      this.$http.post('/api/authenticate/resend_verify_code_email', {
      })
        .then(response => {
          if (response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CompassIcon',
                variant: 'success',
                text: 'The Email message has been sent successfully',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CompassIcon',
                variant: 'danger',
                text: 'The user already exists',
              },
            })
          }
        })
    },
    resend_verify_code_phone() {
      this.$http.post('/api/authenticate/resend_verify_code_phone', {
      })
        .then(response => {
          if (response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CompassIcon',
                variant: 'success',
                text: 'The Sms message has been sent successfully',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CompassIcon',
                variant: 'danger',
                text: 'The user already exists',
              },
            })
          }
        })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },

    confirm() {
      // console.log(JSON.stringify(this.verifyCode))
      // console.log(localStorage.getItem('userData'))
      this.$http.post('/api/profile/confirm_registration', {
        verify_code_email: this.verifyCodeEmail,
        verify_code_phone: this.verifyCodePhone,
      }).then(response => {
        if (response.data.userData && response.status === 200) {
          this.userData = response.data.userData
          // console.log(JSON.stringify(this.userData))
          // console.log(JSON.stringify(response.data))
          localStorage.setItem('userData', JSON.stringify(response.data.userData))
          this.$ability.update(response.data.userData.ability)
          this.$router.push('/')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CompassIcon',
              variant: 'success',
              text: 'The Email message has been sent successfully',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CompassIcon',
              variant: 'danger',
              text: 'Email or SMS code entered incorrectly or missing',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.page_codes{
  display:flex;
  justify-content: center;
  margin: 0 -5px;
}

.page_codes_item{
  margin: 0 5px;
}

.more_information{
  padding:4px;
}
</style>
